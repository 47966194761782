import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store';
import cookie from 'js-cookie'
import * as filters from './filters'
import './assets/iconfont/iconfont.css'
import './assets/css/common.css'
import { api } from './server/api'
import ElementUI from 'element-ui'

import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

import 'element-ui/lib/theme-chalk/index.css'

import NkLoading from '@/components/nkLoading/index'
import './realImg'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

Vue.prototype.$cookie = cookie
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.use(VueI18n)
const i18n = new VueI18n(
  {
    locale: cookie.get('language') || 'zh_CN', // 默认语言
    messages:
      {
        'zh_CN': Object.assign(require('./assets/language/ch'), zhLocale),
        'en': Object.assign(require('./assets/language/en'), enLocale)
      },
    silentTranslationWarn: true
  }
)

locale.i18n((key, value) => i18n.t(key, value)) // 为了实现element插件的多语言切换

Vue.use(VueAwesomeSwiper /* { default options with global component } */)
Vue.use(ElementUI)
Vue.use(VueRouter)
Vue.use(NkLoading)

Vue.prototype.$api = api
Vue.config.productionTip = false

export default new Vue({
  el: '#app',
  i18n,
  router,
  store, // 把store对象添加到vue实例上
  render: h => h(App)
}).$mount('#app')
