let zhLocale = require("element-ui/lib/locale/lang/zh-CN");
module.exports = {
    language: {
        language: "中文",
        english: "英语",
        chinese: "中文",
    },
    menu: {
        home: '首页',
        news: '新闻动态',
        policy: '政策',
        about: '关于我们',
    },
    base: {
        complete: '完成',
        pleaseEnterPhoneNumber: '请输入手机号码',
        pleaseSelectA: '请选择',
        pleaseInput: '请输入 ',
        confirmPassword: '确认密码',
        previousStep: '上一步',
        toResend: '重新发送',
        submit: '提交',
        back: '返回',
        cancel: '取消',
        subTips: '您确定要撤回审核材料吗？撤回后需要重新等待审核时间。',
        loginOut: '退出登入',
        confirm: '确定',
    },
    signIn: {
        setLoginInformation: '设置登入信息',
        validation: '验证',
        email: '邮箱',
        pleaseEnterYourEmailAddress: '请输入您的电子邮箱',
        passwordsAreCaseSensitive: '8 - 16位密码，区分大小写',
        anAlphanumericSymbol: '密码长度该为8-16,密码组合如下：字母，数字或符号',
        nextStepToVerify: '下一步：验证',
        verifyMobilePhoneNumber: '验证手机号码',
        sentToMobilePhoneNumber: '请输入已发送至注册的手机号',
        verificationCodeS: '里的验证码',
        registeredSuccessful: '注册成功',
        accountCreatedSuccessful: '您已经成功创建了GOGO2GO账号，您的登录账号为',
        pleaseCompleteInApplication: '请登录该账号后完成入驻申请。',
        loginImmediate: '立即登录',
        pleaseEnterCorrectEmail: '请输入正确的邮箱',
        pleaseEnterPhoneNumber: '请输入手机号码',
        pleaseEnterCorrectPhone: '请输入正确的手机号码',
        doNotConformToPasswordFormat: '密码格式不符合',
        pleaseEnterPasswordAgain: '请再次输入密码',
        enterPasswordTwice: '两次输入密码不一致!',
        pleaseEnterPassword: '请输入密码',
        pleaseEnterRepeatPassword: '请输入重复密码',
        pleaseEnterCompleteVerificationCode: '请输入完整验证码',
        toResetYourPassword: '重置密码',
        verificationCode: '验证码',
        sendVerificationCode: '发送验证码',
        passwordResetSuccessful: '重置密码成功！',
        successfullyResetAccount: '您已经成功重置GOGO2GO账号',
        passwordSuccessful: '密码成功！',
        canUseNewPasswordToLogInImmediate: '请牢记您的密码切勿泄漏，可以立即使用新密码登录',
        pleaseEnterVerificationCode: '请输入验证码',
        pleaseEnterNewPassword: '请输入新密码',
        pleaseEnterRepeatNewPassword: '请输入重复新密码',
        registeredSellers: '全球开店申请',
        switchLocalMerchants: '如果您已经申请通过，请直接切换卖家中心登录',
        sellerCenter: '卖家中心入口',
        mobilePhoneNumber: '手机号码',
        password: '密码',
        login: '登录',
        createMerchantAccount: '注册商家账户',
        forgotPassword: '忘记密码',
        pleaseEnterYourAccount: '请输入您的账户',
        pleaseEnterYourPassword: '请输入您的密码',
        passwordCannotBeLessThanEight: '输入的密码不能小于8位',
        registerInfo: '注册登入信息',
        scanDownload: '扫码下载App',
    },
    home: {
        definition: '定义',
        homeTips: '全球跨境新未来',
        moveNow: '立即入驻',
        watchVideo: '观看视频',
        model1Title: '链接千亿全球市场',
        model1SubTitle: '海量商机 轻松触达',
        model2Title: '六大平台优势',
        model2SubTitle: '跨境出海更专业',
        model3Title: '入驻即享3大权益',
        model3SubTitle: '助力国货出海全球市场',
        model4Title: '已入驻商家',
        model4SubTitle: '业内领先跨境卖家',
        model5Title: '合作伙伴',
        model5SubTitle: '出海得力助手',
        model6Title: '加入GOGO2GO，轻松开店',
        sellerSettled: '卖家入驻',
        aboutUs: '关于我们',
        moveInNow: '立即入驻',
        sellerSettledIn: '卖家入驻',
        developmentPath: '发展历程',
    },
    settled: {
        instructions: '入驻说明',
        process: '入驻流程',
        investmentPolicy: '招商政策',
        admissionNotice: '入驻须知',
        partnerReferrals: '合作伙伴推荐',
        viewAdmissionRecord: '查看入驻记录',
        addApplicationForm: '填写申请表格',
    },
    news: {
        pleaseEnterContent: '请输入内容',
        featured: '精选',
        editFeatured: '编辑精选',
        recommendReading: '推荐阅读',
    },
    choice: {
        loginAccount: '登录账号',
        registerTips: '如果您没有注册过GOGO2GO账号，需要新注册',
        registerBtnText: '点我注册',
        loginTips: '如果您之前注册过GOGO2GO账号且注册成功',
        loginBtnText: '点我登录'
    },
    record: {
        applicationRecord: '入驻申请记录',
        pleaseSelectSite: '请选择站点',
        noSite: '暂无站点,敬请期待!',
        oneClickApplication: '一键申请入驻',
        submitTips: '您还未创建入驻申请，请尽快提交您的入驻信息',
        admissionNow: '立即申请入驻',
        serialNumber: '序号',
        merName: '店铺名称',
        merType: '商户类型',
        openSite: '开通站点',
        initialTime: '商户申请的初始时间',
        statusUpdateTime: '状态更新时间',
        approvalStatus: '审核状态',
        operate: '操作',
        editMaterial: '编辑材料',
        withdrawData: '撤回资料',
        shopNow: '立即开店',
        checkingData: '查看资料',
    },
    selectType: {
        title: '在GOGO2GO上开展的业务',
        personalTips: '如您是个人开店，可通过“身份证号”方式认证',
        personalText: '个人开店',
        companyTips: '如您是企业开店，可通过“企业名称“营业执照”法人身份证”方式认证',
        companyText: '企业开店'
    },
    stores: {
        merAuthInfo: '商户认证信息',
        setStoreInfo: '设置店铺信息',
        success: '完成',
        businessCompanyName: '营业执照公司名称',
        originalPhoto: '营业执照原件照片（正副本)',
        legalPersonName: '法人姓名',
        documentName: '证件姓名',
        documentNumber: '证件号码',
        legalPersonNumber: '法人身份证号',
        frontIdCard: '法人身份证正面照片',
        idPhoto: '证件照片（正面）',
        reverseIdCard: '法人身份证反面照片',
        idPhotoBack: '证件照片（反面)',
        takingFrontIdCard: '法人手持身份证正面照片',
        takingFrontIdCard1: '手持身份证正面照片',
        takingReverseIdCard: '法人手持身份证反面照片',
        takingReverseIdCard1: '手持身份证反面照片',
        saveDraft: '保存草稿',
        nextStepSettingInfo: '下一步：设置店铺信息',
        selectOpenSite: '选择开通站点',
        merName: '店铺名称',
        merClass: '店铺经营分类',
        pleaseMerClass: '请选择店铺经营分类',
        merLogo: '店铺Logo',
        merInfo: '店铺介绍',
        merAddress: '店铺地址',
        successSubmit: '成功提交申请',
        submitTips1: '您已开始提交资料，请您尽快完成提交。',
        submitTips: '我们的工作人员会尽快审核您的材料，我们的审核在5个工作日内完成（遇节假日、周末、繁忙时段审核时间会顺延）',
        bankInfo: '银行信息',
        bankCardPhoto: '银行卡照片',
        accountName: '账户名称',
        accountNumber: '账户号码',
        bank: '银行',
        pleaseSelectBank: '请选择银行',
        passAuditTips1: '您提交的申请材料，已经通过审核，您可以去【立即开店】或者访问地址：',
        passAuditTips2: '使用手机号和密码，登录后台去管理自己的店铺产品了。',
        certificateType: '证件类型',
        idCard: '身份证',
        passport: '护照',
        driveLicense: '驾照',
        company: '企业',
        person: '个人',
        companyName: '企业名称',
        businessLicenseCode: '营业执照编码',
        frontPhotoBusinessLicense: '营业执照正面照片',
        merInformation: '店铺信息',
        businessCreditCode: '营业执照统一企业信用代码',
        realID: '真实身份证件'
    },
    footer: {
        sellerCenter: '卖家中心',
        contactUs: '联系我们',
        hotline: '招商热线',
        xiamenOffice: '厦门办公室',
        companyAddress: '厦门市湖里区双狮北路21号海上世界1号楼-裙楼（NIUKE跨境总部）',
        feedback: '问题反馈',
        GOGO2GOApp: 'GOGO2GO APP',
        recordInformation: 'Copyright © 2023 厦门纽客云科技服务有限公司. All rights reserved.',
        areaNumber: '闽ICP备2021005183号-3'
    },
    latestStatus: {
        status0: '初始状态',
        status1: '等待提交资料',
        status2: '审核中',
        status3: '审核不通过',
        status4: '审核通过',
    },
    newStatus: {
        allStatus: '全部动态',
        GOGO2GONews: 'GOGO2GO 新闻',
        marketInsight: '市场洞察',
        operatingDryGoods: '运营干货',
        GOGO2GOActive: 'GOGO2GO 活动'
    },
    openTips: {
        tipsTitle: '确定开通提醒',
        successSubmitAudit: '成功提交审核!',
        tips1: '您确定要开通{merchant}店铺吗?',
        tips2: '系统默认将【商户认证信息】和【店铺信息】自动提交审核。如有修改，请等待审核通过后，进入店铺修改即可。',
    },
    '帮助中心': '帮助中心',
    '基本信息': '基本信息',
    '我的账号': '我的账号',
    '基础设置': '基础设置',
    '添加商品': '添加商品',
    '店铺装修': '店铺装修',
    '订单管理': '订单管理',
    '退款/退货流程': '退款/退货流程',
    '发货流程': '发货流程',
    '我的钱包': '我的钱包',
    '店铺营销': '店铺营销',
    '营销活动': '营销活动',
    '营销工具': '营销工具',
    'Show管理': 'Show管理',
    '直播管理': '直播管理',
    '商家端App登录': '商家端App登录',
    '商家端App直播': '商家端App直播',
    '商家端入驻流程': '商家端入驻流程',
    '商家端操作手册': '商家端操作手册',
    ...zhLocale
}
