let zhLocale = require('element-ui/lib/locale/lang/zh-CN');
module.exports = {
  language: {
    language: 'English',
    english: 'English',
    chinese: 'Chinese',
  },
  menu: {
    home: 'Home',
    news: 'News',
    policy: 'Policy',
    about: 'About Us',
  },
  base: {
    complete: 'Complete',
    pleaseEnterPhoneNumber: 'Please enter mobile number',
    pleaseSelectA: 'Please Select',
    pleaseInput: 'Please Input ',
    confirmPassword: 'Re-enter Password',
    previousStep: 'Previous',
    toResend: 'Re-send',
    submit: 'Submit',
    back: 'Back',
    cancel: 'Cancel',
    subTips: 'Confirm to withdraw audit materials? If materials withdrawn, you need to wait for the audit once more. ',
    loginOut: 'Login Out',
    confirm: 'Confirm',
  },
  signIn: {
    setLoginInformation: 'Set Login Information',
    validation: 'Verify',
    email: 'Email',
    pleaseEnterYourEmailAddress: 'Please enter your email address',
    passwordsAreCaseSensitive: 'Password must be 8-16 characters and case sensitive',
    anAlphanumericSymbol: 'Password must be 8-16 characters consisting of letters, numbers or symbols',
    nextStepToVerify: 'Next: Verify',
    verifyMobilePhoneNumber: 'Verify Mobile Number',
    sentToMobilePhoneNumber: 'Verification code has been sent to registered mobile number',
    verificationCodeS: 'please enter',
    registeredSuccessful: 'Successful Registration',
    accountCreatedSuccessful: 'You have created MONMALL account successfully, and your login account is',
    pleaseCompleteInApplication: 'Please log in the account to complete settlement application',
    loginImmediate: 'Login Now',
    pleaseEnterCorrectEmail: 'Please enter correct email address',
    pleaseEnterPhoneNumber: 'Please enter mobile number',
    pleaseEnterCorrectPhone: 'Please enter correct mobile number',
    doNotConformToPasswordFormat: 'Password format does not match',
    pleaseEnterPasswordAgain: 'Please re-enter password',
    enterPasswordTwice: 'The entered passwords do not match!',
    pleaseEnterPassword: 'Please enter password',
    pleaseEnterRepeatPassword: 'Please re-enter password',
    pleaseEnterCompleteVerificationCode: 'Please enter complete captcha code',
    toResetYourPassword: 'Reset Password',
    verificationCode: 'Captcha',
    sendVerificationCode: 'Send Verification Code',
    passwordResetSuccessful: 'Reset Password Successfully',
    successfullyResetAccount: 'You have successfully reset password of MONMALL account',
    passwordSuccessful: 'please log in',
    canUseNewPasswordToLogInImmediate: 'Please remember your password and do not reveal it. You can log in to your account with new password.',
    pleaseEnterVerificationCode: 'Please enter verification code',
    pleaseEnterNewPassword: 'Please enter new password',
    pleaseEnterRepeatNewPassword: 'Please re-enter new password',
    registeredSellers: 'Global Selling Application',
    switchLocalMerchants: 'If your application has been approved, please switch to Seller Center to log in to your account.',
    sellerCenter: 'Seller Center Entrance',
    mobilePhoneNumber: 'Mobile Number',
    password: 'Password',
    login: 'Login',
    createMerchantAccount: 'Create seller account',
    forgotPassword: 'Forgot password',
    pleaseEnterYourAccount: 'Please enter mobile number',
    pleaseEnterYourPassword: 'Please enter password',
    passwordCannotBeLessThanEight: 'The entered password should not be less than 8 characters',
    registerInfo: 'Register Login Information',
    scanDownload: 'Scan to Download App',
  },
  home: {
    definition: 'Define',
    homeTips: 'New Future of Cross-border E-commerce Worldwide',
    moveNow: 'Settle Now',
    watchVideo: 'Watch Video',
    model1Title: 'Link 100 Billion Global Market',
    model1SubTitle: 'Easy Access to Massive Leads',
    model2Title: '6 Major Platform Advantages',
    model2SubTitle: 'Professionally Boost Business Overseas',
    model3Title: 'Enjoy 3 Benefits Upon Settlement',
    model3SubTitle: 'Boost Sales of Made-in-China Merchandise Overseas',
    model4Title: 'Settled Merchant',
    model4SubTitle: 'Industry-leading Cross-border E-commerce Sellers',
    model5Title: 'Partnership',
    model5SubTitle: 'Good Helper for Overseas Sales',
    model6Title: 'Join MONMALL to Start Business Easily',
    sellerSettled: 'Merchant Settlement',
    aboutUs: 'About Us',
    moveInNow: 'Settle Now',
    sellerSettledIn: 'Merchant Settlement',
    developmentPath: 'Development History',
  },
  settled: {
    instructions: 'Settlement Instructions',
    process: 'Settlement Procedures',
    investmentPolicy: 'Leasing Policy',
    admissionNotice: 'Settlement Guidance',
    partnerReferrals: 'Settlement Guidance',
    viewAdmissionRecord: 'View Settlement Records',
    addApplicationForm: 'Fill out Application Form',
  },
  news: {
    pleaseEnterContent: 'Please enter content',
    featured: 'Featured',
    editFeatured: 'Edit Featured',
    recommendReading: 'Recommended News',
  },
  choice: {
    loginAccount: 'Login',
    registerTips: "If you haven't registered MONMALL account, please register a new account",
    registerBtnText: 'Click to Register',
    loginTips: 'If you have already registered MONMALL account successfully before',
    loginBtnText: 'Click to Login',
  },
  record: {
    applicationRecord: 'Settlement Application Record',
    pleaseSelectSite: 'Select Site',
    noSite: 'No site yet, coming soon!',
    oneClickApplication: 'One-button application for settlement',
    submitTips: "You haven't created an application for settlement, please submit your settlement information as soon as possible",
    admissionNow: 'Apply Settlement Now',
    serialNumber: 'No.',
    merName: 'Store Name',
    merType: 'Store Type',
    openSite: 'Open Site',
    initialTime: 'Application Create Time',
    statusUpdateTime: 'Status Update Time',
    approvalStatus: 'Audit Status',
    operate: 'Actions',
    editMaterial: 'Edit Materials',
    withdrawData: 'Withdraw Materials',
    shopNow: 'Open Store Now',
    checkingData: 'View Materials',
  },
  selectType: {
    title: 'Start Your Business on MONMALL',
    personalTips: 'If for individual store, please verify via ID Card Number.',
    personalText: 'Individuals',
    companyTips: "If for business store, please verify via Business Name, Business License and Legal Representative's ID Card.",
    companyText: 'Business',
  },
  stores: {
    merAuthInfo: 'Merchant Authentication Information',
    setStoreInfo: 'Set Store Information',
    success: 'Complete',
    businessCompanyName: 'Company Name on Business License',
    originalPhoto: 'Photos of original business license (original & duplicate)',
    legalPersonName: 'Name of legal representative',
    documentName: 'Name on ID Document',
    documentNumber: 'ID Document Number',
    legalPersonNumber: 'Legal Representative ID Card Number',
    frontIdCard: 'Front side ID card photo of the legal representative',
    idPhoto: 'ID Document Photo (Front Side)',
    reverseIdCard: 'Back side ID card photo of the legal representative',
    idPhotoBack: 'ID Document Photo (Back Side)',
    takingFrontIdCard: 'Photo of legal representative holding the front side ID card',
    takingReverseIdCard: 'Photo of legal representative holding the back side ID card',
    takingFrontIdCard1: 'Front photo of holding ID card',
    takingReverseIdCard1: 'Reverse photo of ID card in hand',
    saveDraft: 'Save Draft',
    nextStepSettingInfo: 'Next: Set Store Information',
    selectOpenSite: 'Select Open Site',
    merName: 'Store Name',
    merClass: 'Category',
    pleaseMerClass: 'Please Select Category',
    merLogo: 'Store Logo',
    merInfo: 'Store Profile',
    merAddress: 'Store Location',
    successSubmit: 'You have submitted application successfully!',
    submitTips1: 'You have started to submit materials, please complete the submission as soon as possible.',
    submitTips: 'Our staff will audit your materials in a short time and the audit will be finished within 5 working days (audit time will be postponed during holidays, weekends and busy hours).',
    bankInfo: 'Bank Information',
    bankCardPhoto: 'Photo of Bank Card',
    accountName: 'Account Name',
    accountNumber: 'Bank Account Number',
    bank: 'Bank',
    pleaseSelectBank: 'Please Select Bank',
    passAuditTips1: 'Your submitted application materials have been approved, and you can go to【Open Store Now】or visit website link: ',
    passAuditTips2: 'to log in seller center with mobile number and password to manage your own store products. ',
    certificateType: 'ID Type',
    idCard: 'ID Card',
    passport: 'Passport',
    driveLicense: "Driver's License",
    company: 'Company',
    person: 'Person',
    companyName: 'Business Name',
    businessLicenseCode: 'Business License Number',
    frontPhotoBusinessLicense: 'Front side business license photo',
    merInformation: 'Store Information',
    businessCreditCode: 'Business License Unified Social Credit Code',
    realID: 'ID Card',
  },
  footer: {
    sellerCenter: 'Seller Center',
    contactUs: 'Contact Us',
    hotline: 'Business Hotline',
    xiamenOffice: 'Office Location: Xiamen',
    companyAddress: 'NIUKE Cross-Border Headquarters Podium Building 1, Sea World, No. 21, Shuangshi North Road, Huli District, Xiamen City',
    feedback: 'Feedback',
    monmallApp: 'MONMALL APP',
    recordInformation: 'Copyright ©2022 NIUKE Technical Support. All Rights Reserved.',
    areaNumber: 'Fujian ICP No. 2021005183号-3',
  },
  latestStatus: {
    status0: 'Initial State',
    status1: 'Awaiting Submission',
    status2: 'Under Review',
    status3: 'Review Failed',
    status4: 'Approved',
  },
  newStatus: {
    allStatus: 'All',
    monmallNews: 'MONMALL News',
    marketInsight: 'Market Insights',
    operatingDryGoods: 'Operating Tips',
    monmallActive: 'MONMALL Campaigns',
  },
  openTips: {
    tipsTitle: 'Confirm to Open Notifications',
    successSubmitAudit: 'submitted for Audit Successfully!',
    tips1: 'If you confirm to open a store of  {merchant} site. ',
    tips2: '[Merchant Authentication Information] and [Store Information] will be automatically submitted for the audit by default in the system. If any modification, please go to the store to modify information after the audit is approved.',
  },
  '帮助中心': 'Help Center',
  '基本信息': 'Basic Information',
  '我的账号': 'My Account',
  '基础设置': 'Basic Settings',
  '添加商品': 'Add Products',
  '店铺装修': 'Store Decoration',
  '订单管理': 'Order Management',
  '退款/退货流程': 'Refund/Return Process',
  '发货流程': 'Shipping Process',
  '我的钱包': 'My Wallet',
  '店铺营销': 'Store Marketing',
  '营销活动': 'Marketing Campaign',
  '营销工具': 'Marketing Tool',
  'Show管理': 'Show Management',
  '直播管理': 'Live Management',
  '商家端App登录': 'Merchant app - Login',
  '商家端App直播': 'Merchant app - Live',
  '商家端入驻流程': 'Settling process',
  '商家端操作手册': 'Operation Manual',
  ...zhLocale,
};
