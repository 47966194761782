<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  -moz-user-select: none;
}
p {
  word-wrap:break-word;
  /*或者是下面一种方法让单词强制换行*/
  /*
  word-break: break-all;
  */
}
ul,
ol {
  list-style: none;
}
</style>
