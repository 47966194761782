import Vue from 'vue'
import VueRouter from 'vue-router'
// import VueMain from '../main'
import consoleHome from './routes/consoleHome'

Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push

// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior() {
  //   return { x: 0, y: 0 }
  // },
  routes: [
    ...consoleHome,
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})
// router.beforeEach((to, from, next) => {
//   if (to.path === '/signIn/settled/applicationRecord') {//短路逻辑，有就可以继续执行，没有就跳转到登录页面
//     // console.log('12325646');
//     next('/signIn/settled/choice')
//   } else {
//     next()//跳转登录页面
//   }
// })
// 用于控制启动预加载loading,路由加载完隐藏loading
// eslint-disable-next-line no-undef
router.afterEach((to) => {
  window.scrollTo(0, 0);
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  if (to.meta.title) {
    document.title = "GOGO2GO - " + to.meta.title
  }
})

// eslint-disable-next-line no-undef
export default router
