import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import Cookies from "js-cookie";

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: Cookies.get('language') || 'zh_CN',//默认语言
  messages: {
    'zh_CN': Object.assign(require('./ch'), zhLocale),
    'en_US': Object.assign(require('./en'), enLocale)
  }
})

export default i18n
