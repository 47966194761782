import Vue from 'vue'

/**
 * 全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
 * 在网络慢的时候，加载图片多的时候，可以达到占位图的效果
 * 使用方法：<img src="默认图片.png" v-real-img="真实图片.png">
 */
Vue.directive('real-img', async (el, binding) => {
  let imgURL = binding.value// 获取图片地址
  // eslint-disable-next-line global-require
  const defaultURL = require('@/assets/image/home/banner.png')// 获取默认图片地址
  if (imgURL) {
    const exist = await imageIsExist(imgURL)
    if (exist) {
      el.setAttribute('src', imgURL)
    } else if (defaultURL) {
      el.setAttribute('src', defaultURL)
    }
  } else {
    el.setAttribute('src', defaultURL)
  }
})

/**
 * 检测图片是否存在
 * @param url
 */
// eslint-disable-next-line func-names
const imageIsExist = function (url) {
  return new Promise(resolve => {
    let img = new Image()
    // eslint-disable-next-line func-names
    img.onload = function () {
      if (this.complete === true) {
        resolve(true)
        img = null
      }
    }
    // eslint-disable-next-line func-names
    img.onerror = function () {
      resolve(false)
      img = null
    }
    img.src = url
  })
}
