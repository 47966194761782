export function keywordStatusFilter(status) {
  const statusMap = {
    'text': '文字消息',
    'image': '图片消息',
    'news': '图文消息',
    'voice': '声音消息'
  }
  return statusMap[status]
}

// 动态类型
export function typeValue(status) {
  const statusMap = {
    '': i18n.t('newStatus.allStatus'),
    1: i18n.t('newStatus.monmallNews'),
    2: i18n.t('newStatus.marketInsight'),
    3: i18n.t('newStatus.operatingDryGoods'),
    4: i18n.t('newStatus.monmallActive'),
  }
  return statusMap[status]
}

// 商户类型
export function merchantType(status) {
  const statusMap = {
    '1': i18n.t('stores.person'),
    '2': i18n.t('stores.company'),
  }
  return statusMap[status]
}

// 开通站点
export function openSite(status) {
  const statusMap = {
    '1': '菲律宾',
    '2': '马来西亚',
    '3': '新加坡',
    '4': '泰国',
    '5': '印尼',
    '6': '越南',
    '7': '缅甸',
  }
  return statusMap[status]
}

import i18n from "@/assets/language/i18n";

// 最新状态
export function latestStatus(status) {
  const statusMap = {
    '0': i18n.t('latestStatus.status0'),
    '1': i18n.t('latestStatus.status1'),
    '2': i18n.t('latestStatus.status2'),
    '3': i18n.t('latestStatus.status3'),
    '4': i18n.t('latestStatus.status4'),
  }
  return statusMap[status]
}


// {{ props.row.pay_type | payTypeFilter }}
