import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        token: Cookies.get("token"),
        userInfo: Cookies.get("userInfo"),
    },
    mutations: {
        setToken(state, payload) {
            Cookies.set('token', payload);
            state.token = payload || ''
        },
        setInfo(state, payload) {
            Cookies.set('userInfo', payload);
            // 之后才是修改state中的状态
            state.userInfo = payload || '';
        },
    }
})

export default store;