import i18n from "@/assets/language/i18n";

export default [
  {
    path: '/',
    component: () => import('@/views/pageView.vue'),
    children: [
      {
        path: '/',
        name: 'console-home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: i18n.t('menu.home'), affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/settled',
        name: 'settled',
        component: () => import('@/views/home/settled.vue'),
        meta: {
          title: '申请入驻', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/new',
        name: 'new',
        component: () => import('@/views/new/index.vue'),
        meta: {
          title: '新闻动态', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/newData',
        name: 'new-data',
        component: () => import('@/views/new/newData.vue'),
        meta: {
          title: '新闻详情', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/cooperate',
        name: 'cooperate',
        component: () => import('@/views/cooperate/index.vue'),
        meta: {
          title: '卖家入驻', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/policy',
        name: 'policy',
        component: () => import('@/views/policy/index.vue'),
        meta: {
          title: '政策', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/helpCenter',
        name: 'HelpCenter',
        component: () => import('@/views/helpCenter/index.vue'),
        meta: {
          title: i18n.t('帮助中心'), affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/index.vue'),
        meta: {
          title: '了解我们', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@/views/search/index.vue'),
        meta: {
          title: '搜索结果', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/joinUs',
        name: 'joinUs',
        component: () => import('@/views/joinUs/index.vue'),
        meta: {
          title: '加入我们', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      }
    ],
  },
  {
    path: '/',
    component: () => import('@/views/signIn/best.vue'),
    children: [
      {
        path: '/signIn/register',
        name: 'register',
        component: () => import('@/views/signIn/register/index.vue'),
        meta: {
          title: '注册', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: () => import('../../views/signIn/forgetPassword/index.vue'),
        meta: {
          title: '找回密码', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/views/signIn/application.vue'),
    children: [
      {
        path: '/signIn/settled/choice',
        name: 'choice',
        component: () => import('../../views/signIn/Settled/choice.vue'),
        meta: {
          title: '注册或登入', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/signIn/settled/applicationRecord',
        name: 'applicationRecord',
        component: () => import('../../views/signIn/Settled/applicationRecord.vue'),
        meta: {
          title: '入驻', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/signIn/settled/selectType',
        name: 'selectType',
        component: () => import('../../views/signIn/Settled/selectType.vue'),
        meta: {
          title: '开店入驻', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/signIn/settled/enterpriseStores',
        name: 'enterpriseStores',
        component: () => import('../../views/signIn/Settled/enterpriseStores.vue'),
        meta: {
          title: '跨境企业入驻', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/signIn/settled/crossBorderPersonal',
        name: 'crossBorderPersonal',
        component: () => import('../../views/signIn/Settled/crossBorderPersonal.vue'),
        meta: {
          title: '跨境个人入驻', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
      {
        path: '/signIn/settled/mainlandPersonalShop',
        name: 'mainlandPersonalShop',
        component: () => import('../../views/signIn/Settled/mainlandPersonalShop.vue'),
        meta: {
          title: '本土个人入驻', affix: true, myCenter: true, redirectIfLoggedIn: true
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/signIn/login/index.vue'),
    meta: {
      title: '登入', affix: true, myCenter: true, redirectIfLoggedIn: true
    }
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('@/views/signIn/forgetPassword/index.vue'),
    meta: {
      title: '找回密码', affix: true, myCenter: true, redirectIfLoggedIn: true
    }
  },

]
