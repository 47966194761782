import http from './http'

export const api = {
    getProductList: function (data) {
        return http.get('/get_group_key_value', data)
    },
    platformList: function (data) {
        return http.get('/platform_introduction/list', data)
    },
    helpIndex: function (data) {
        return http.get('/help/index', data)
    },
    platformData: function (data) {
        return http.get('/platform_introduction/list', data)
    },
    newsList: function (data) {
        return http.get('/news/list', data)
    },
    newInfo: function (data) {
        return http.get('/news/new_info', data)
    },
    newDetails: function (id, data) {
        return http.get(`/news/details/${id}`, data)
    },
}
